import React, {Fragment, useEffect} from 'react'
import {Col, Image, Row} from 'react-bootstrap'
import PageTitle from 'src/app/modules/widgets/PageTitle'
import CustomBtn from 'src/app/modules/widgets/CustomBtn'
import {useBusiness} from '../../../core/action'
import CustomProgressBar from '../../../../widgets/StepProgressBar'
import {svgUrl} from 'src/_kiloit/helpers'
import {useNavigate} from "react-router-dom";
import {useAddProduct} from "../add-product/core/actions";
import {useAuth} from "../../../../auth/core/action";

const CompletedPage = () => {
    const {handleReInitialState, isCompleted} = useBusiness();
    const navigate = useNavigate();
    const {selectedProduct, products} = useAddProduct();
    const {redirectToProduct} = useAuth();

    const next = () => {
        handleReInitialState(false);
        const app = products.find((product: any) => product["id"] === selectedProduct);
        if (app)
            redirectToProduct(app?.name);
    }

    useEffect(() => {
        if (!isCompleted) {
            navigate("/product-suite/add-product");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Row className="mt-3">
                <Col lg={5} className='mb-5'>
                    <PageTitle title="Completed" subtitle="Congratulation, KiloFood has been activated! "
                               description="You have completed Information successfully. To manage your foods, just click on “Get Started” button."/>
                    <Row className='d-flex justify-content-center justify-content-lg-start'>
                        <Col xs={6}>
                            <CustomBtn func={next} title='Get Started'/>
                        </Col>
                    </Row>
                </Col>
                <Col lg={7} className='d-flex justify-content-center justify-content-lg-end order-first order-lg-last'>
                    <div>
                        <div className="me-3">
                            <CustomProgressBar/>
                        </div>

                        <div>
                            <Image className=' p-3 mb-5  rounded-4 w-100'
                                   style={{maxWidth: 460, boxShadow: "0px 0px 8px 2px rgba(99, 99, 99, 0.2)"}} fluid
                                   src={svgUrl("completed.svg")} alt=''/>
                        </div>
                    </div>
                </Col>
            </Row>

        </Fragment>
    )
}

export default CompletedPage


