import {useDispatch} from "react-redux";
import {reInitialState, setAppId, setBusiness, setCategoryOptions, setErrorMessage, setLoading} from "./reducer";
import {AppBusinessModel} from "./model";
import {reqCreateMerchant, reqGetBusinessOptions} from "./request";
import {useAppSelector} from "../../../redux/hooks/hook";
import {useNavigate} from "react-router-dom";
import {setSelectedProduct} from "../components/product-suite/add-product/core/reducer";
import {useFile} from "../../file-upload/core/action";
import {useSweetAlert} from "../../../../_kiloit/helpers/alert/alert";

const useBusiness = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        sliderImages,
        categoryOptions,
        business,
        loading,
        isCompleted,
        errorMessage
    } = useAppSelector(state => state.business);
    const {uploadFile, deleteFile} = useFile();
    const {alertError, alertConfirm} = useSweetAlert();

    const getBusinessOptions = async (id: number) => {
        dispatch(setAppId(id));
        return await reqGetBusinessOptions(id).then((res: any) => {
            const options = res.data.data.map((option: AppBusinessModel) => {
                return {
                    id: option.id,
                    label: option.name,
                    value: option.id,
                }
            });
            dispatch(setCategoryOptions(options));
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const handleSetBusiness = (payload: any) => {
        return dispatch(setBusiness(payload));
    }

    const uploadBanner = async (payload: any, appId: number, formik: any, name: string, duration: number) => {
        dispatch(setLoading(true));
        return await uploadFile(payload, appId).then((res: string) => {
            setTimeout(() => {
                dispatch(setLoading(false));
                const updatedBanners = [...formik.values[name], res];
                dispatch(setBusiness({banners: updatedBanners}));
                formik.setFieldValue(name, updatedBanners);
            }, duration + 500);
        }).catch((err: any) => {
            dispatch(setLoading(false));
            console.log(err);
            dispatch(setErrorMessage(err.message));
        });
    };

    const deleteBanner = async (url: string, formik: any) => {
        return await deleteFile(url).then((res: any) => {
            const banners = business.banners.filter((banner: string) => {
                return banner !== url;
            })
            dispatch(setBusiness({banners}));
            formik.setFieldValue("banners", banners);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const createMerchant = async (payload: any) => {
        dispatch(setLoading(true));
        return await reqCreateMerchant(payload).then((res: any) => {
            console.log(res);
            dispatch(setLoading(false));
            handleReInitialState(true);
            navigate("/product-suite/completed");
        }).catch((err: any) => {
            console.log(err);
            dispatch(setLoading(false));
            alertError({
                text: err?.response?.data?.status === 400 ? err?.response?.data?.message.replaceAll("_", " ") : err?.message,
                preConfirm: () => {
                    alertConfirm({
                        textConfirm: "This process is denied!",
                        cancelButtonText: "Try again",
                        confirmButtonText: "Cancel",
                        onCancel: () => console.log(payload),
                        preConfirm: () => {
                            handleReInitialState(true);
                            navigate("/");
                        }
                    });
                }
            });
        });
    }

    const handleReInitialState = (payload = false) => {
        dispatch(reInitialState(payload));
        !payload && dispatch(setSelectedProduct(null));
    }

    const handleSetErrorMessage = (payload: string | null) => {
        return dispatch(setErrorMessage(payload));
    }

    return {
        sliderImages,
        categoryOptions,
        business,
        loading,
        isCompleted,
        errorMessage,
        getBusinessOptions,
        handleSetBusiness,
        uploadBanner,
        deleteBanner,
        createMerchant,
        handleReInitialState,
        handleSetErrorMessage
    }
}

export {useBusiness}