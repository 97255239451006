import React, {useState} from "react";
import {Button, Col, Dropdown, Image, Row} from "react-bootstrap";
import grid from "../../assets/images/users/grid.svg";
import {useAppSelector} from "../../../app/redux/hooks/hook";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../app/modules/auth/core/action";

const BrowseAppsDropdown = () => {
    const {installedProduct} = useAppSelector(state => state.addProduct);
    const [isToggle, setIsToggle] = useState(false);
    const navigate = useNavigate();
    const {redirectToProduct} = useAuth();

    return (
        <React.Fragment>
            <Dropdown
                align="end"
                show={isToggle}
                className="topbar-head-dropdown ms-1 header-item"
                id="browseAppDropdown"
                onToggle={() => setIsToggle(!isToggle)}
            >
                <Dropdown.Toggle
                    id="browseApp"
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-dark rounded-circle arrow-none"
                >
                    <Image width={25} height={25} src={grid}/>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className={`browse-app dropdown-menu-md dropdown-menu-end p-0 ${isToggle ? "burger-icon" : ""}`}
                >
                    <div className="dropdown-head rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="text-black mb-0 fs-16 fw-semibold">
                                        Your Products
                                    </h6>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <main>
                        <section
                            className="px-3 cursor-pointer">
                            <Row xs={3} className="g-3">
                                {
                                    installedProduct.map((item) => (
                                        <Col key={item.id}>
                                            <div onClick={() => redirectToProduct(item.name ?? "")}>
                                                <div
                                                    className="text-center">
                                                    <Image
                                                        style={{width: 50, height: 50}}
                                                        className="p-2 border border-dark rounded-circle hamburger-menu-list"
                                                        src={item?.icon || ""} fluid alt=""/>
                                                </div>
                                                <p style={{fontSize: 11}}
                                                   className="text-center text-truncate mt-1">{item.name}</p>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </section>
                    </main>
                    <footer className="mt-5 mb-4 w-100 d-flex justify-content-center">
                        <Button onClick={() => {
                            setIsToggle(!isToggle);
                            navigate("/product-suite/add-product");
                        }} variant="main" className="rounded-pill px-5 mt-5" style={{fontSize: 12}}>Install more
                            products</Button>
                    </footer>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
};

export default BrowseAppsDropdown;
